import { message } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './AuthContext';
import './login.css';
import 'firebase/auth';
function Signin(){
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const { user, logout } = UserAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    try {
      if(email == 'survey@us.broad-ocean.com' && "register@us.broad-ocean.com"){

        await signIn(email, password)
 
      navigate('/survey')

      } else if (email == 'hr@us.broad-ocean.com'){

        await signIn(email, password)
 
      navigate('/account')

      }else {

        await signIn(email, password)
 
      navigate('/FileUpload')

      }
      
      
      
    } catch (e) {
      setError(e.message)
      console.log(e.message)
      message.warn("User name or password incorrect")
    }
  };

  useEffect(() => {
    logout();
}, [])
  
return (


    
  <div className='signwrapper'>
    {/* <div className='account-title'>HR Login</div> */}
    {/* <div className='account-title'> {new Date().getFullYear()} Broad-Ocean Motor, US Employee Resource Center</div> */}
    <div className='sign-context'>
      <div className='sign-context-left'>
        <div className='account-title'>Broad-Ocean Motor 30th Anniversary Celebration Invitation - Event Questionnaires</div>
      <form className='signin-form' onSubmit={handleSubmit}>
        <div className='signin-form-block'>
        <div className='signin-email'>
          <label className='signin-text'>Username: </label>
          <input onChange={(e) => setEmail(e.target.value)} className='sigin-email-input' type='email' />
        </div>
        <div className='signin-password'>
          <label className='signin-text'>Password: </label>
          <input onChange={(e) => setPassword(e.target.value)} className='sigin-password-input' type='password' />
        </div>
        </div>
        
        <button className='hr-button'>
          Sign In
        </button>
      </form>
   
      </div>
      {/* <div className='sign-context-right'>


      <div className="signin-text"><a href="https://workstation2.bomus.local">Broad-Ocean Motor IT Ticket system</a></div>

      </div> */}
      </div>
{/* <div className='signin-text'>Technical Issue Support: itsupport@us.broad-ocean.com</div>
<div className='signin-text'>Phone: 832-421-7446</div> */}
    </div>





);




}
export default Signin;